<div class="background" *ngIf="!isEmbed"></div>
<div class="background-color" *ngIf="isEmbed"></div>
<div class="container">
  <div class="center-align" *ngIf="!isEmbed">
    <a href="https://sidequestvr.com" target="_top" >
      <img src="../../assets/images/logo.png" class="title-spacing responsive-img logo-max-width"/>
    </a>
    <br><br>
    <h3 class="white-text sub-title-spacing">App Lab Games</h3>
  </div>
  <div class="row">
    <div class="col s12 m4 relative" *ngFor="let app of apps">
      <app-app-lab-game [app]="app"></app-app-lab-game>
    </div>
  </div>
  <div class="center-align more-spacing">
    <a class="suggest-link btn btn-flat pink white-text" href="https://sidequestvr.com/suggest-app" target="_blank">
      Suggest An App
    </a>
    <h5 class="center-align title-spacing white-text" [ngClass]="{'white-text': !isLight}">
      {{isEmbed ? 'powered with love by' : 'more games & apps on'}}
    </h5>
    <a href="https://sidequestvr.com" target="_top">
      <img src="{{isLight ? '../../assets/images/logo-black.png' : '../../assets/images/logo.png'}}" class=" responsive-img logo-max-width"/>
    </a>
    <div *ngIf="!isEmbed">
      <h4 class="white-text title-spacing">Embed on Your Website</h4>
      <div class="embed-code" *ngIf="!isEmbed">
        &lt;iframe src="https://applab.games/?embed=1" frameborder="0" class="iframe" width="100%" height="500px">&lt;/iframe>
      </div>
    </div>
  </div>

</div>
<app-footer *ngIf="!isEmbed"></app-footer>

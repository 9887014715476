<div class="footer-lining"></div>
<div class="footer-bottom">
  <div class="row no-margin">
    <div class="col s6 right-align padding">
      <a href="https://www.khronos.org/"><img src="../../assets/images/khronos.png" class="responsive-img"/></a>
    </div>
    <div class="col s6 padding">
      <a href="https://www.thevrara.com/"><img src="../../assets/images/vrar.png" class="responsive-img"/></a>
    </div>
  </div>
</div>
<div class="footer-container">
  <div class="padding white-text">
    <div class="row">
      <div class="col s12 m6 extra-padding-right">
        <!--        <div class="right">-->
        <!--          <img src="../../assets/images/openxr.png" class="responsive-img max-height"/>-->
        <!--          <img src="../../assets/images/vrara.jpg" class="responsive-img max-height"/>-->
        <!--        </div>-->
        <img src="../../assets/images/logo.png" class="responsive-img logo-max-width"/><br><br>
        SideQuest is a place to get more apps for your Oculus Quest, it is a completely safe way to enjoy some
        cutting edge content in VR and expand the capability of your standalone VR headset. We make it easier
        for users to access content that is not yet available on the Oculus Store and as a proving ground for
        developers to validate their content and kick start their communities. SideQuest is completely free
        for users and developers and always will be. We are committed to supporting the VR industry. <br><br>
        Proud members of the Khronos Group and The VR/AR Association.<br><br>
      </div>
      <div class="col s12 m6 extra-padding-left">
        <div class="row">
          <div class="col s6">
            <h5>Quick Links</h5>
            <div class="title-under"></div>
            <br>
            <div class="row no-margin">
              <div class="col s12 no-padding">
                <div class="footer-item">
                  <a href="https://sidequestvr.com">Home</a>
                </div>
                <div class="footer-item">
                  <a href="https://sidequestvr.com/setup-howto">Get SideQuest</a>
                </div>
                <div class="footer-item">
                  <a href="https://sidequestvr.com/branding">Brand Guide</a>
                </div>
                <div class="footer-item">
                  <a href="https://sdq.st/devnews" target="_blank">Developer Mailing List</a>
                </div>
                <div class="footer-item">
                  <a href="https://sdq.st/roster" target="_blank">Legends Roster</a>
                </div>
                <div class="footer-item">
                  <a href="https://sdq.st/getpaid" target="_blank">Play Games & Earn Rewards</a>
                </div>
                <div class="footer-item">
                  <a href="https://sidequestvr.com/privacy">Privacy Policy</a>
                </div>
              </div>
              <div class="col s12 m6 no-padding">
              </div>
            </div>

          </div>
          <div class="col s6">
            <h5>Follow Us</h5>
            <div class="title-under"></div>
            <br>
            <div class="row no-margin">
              <div class="col s12 no-padding">
                <div class="footer-item">
                  <a href="https://sdq.st/discord" target="_blank"><img class="category-icon vertical-align" src="../../assets/images/Discord.svg"/> Discord</a>
                </div>
                <div class="footer-item">
                  <a href="https://www.twitter.com/SideQuestVR" target="_blank"><img class="category-icon vertical-align" src="../../assets/images/Twitter.svg"/> Twitter</a>
                </div>
                <div class="footer-item">
                  <a href="https://www.reddit.com/r/sidequest" target="_blank"><img class="category-icon vertical-align" src="../../assets/images/Reddit.svg"/> Reddit</a>
                </div>
                <div class="footer-item">
                  <a href="https://www.patreon.com/SideQuestVR" target="_blank"><img class="category-icon vertical-align" src="../../assets/images/Patreon.svg"/> Patreon</a>
                </div>
              </div>
            </div>
          </div>
        </div></div>
    </div>
  </div>
</div>

